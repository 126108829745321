<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-card>
        <v-data-table
          :headers="headers"
          :items="desserts"
          :options.sync="options"
          :server-items-length="totalDesserts"
          :loading="loading"
          dense
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Program Pinjaman</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <!-- <v-text-field
              v-model="search"
              @keyup.enter="searching()"
              append-icon="mdi-magnify"
              label="Cari"
              single-line
              hide-details
            ></v-text-field> -->
              <v-spacer></v-spacer>

              <v-dialog v-model="dialog" max-width="700px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> Tambah </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="editedItem.nama" label="Nama *"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            type="number"
                            v-model="editedItem.maks_pinjam"
                            label="Maksimal Pinjam *"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            type="number"
                            v-model="editedItem.lama_angsuran"
                            label="Lama_Angsuran *"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field v-model="editedItem.bunga" label="bunga (%) *"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-checkbox v-model="editedItem.aktif" @change="aktifCheck()">
                            <template v-slot:label>
                              <div>Aktif</div>
                            </template>
                          </v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <label for="">Jenis Upah *</label>
                          <v-select
                            v-model="editedItem.jenis_upah"
                            :items="jenis_upah"
                            item-text="text"
                            item-value="value"
                            persistent-hint
                            return-object
                            single-line
                            dense
                            outlined
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="close"> Batal </v-btn>
                    <v-btn color="primary" @click="save"> Simpan </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" @click="closeDelete">Cancel</v-btn>
                  <v-btn color="secondary" @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog> -->
            </v-toolbar>
          </template>

          <template v-slot:item.no="{ item, index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.maks_pinjam="{ item }">
            {{ new Intl.NumberFormat(['id']).format(item.maks_pinjam) }}
          </template>
          <template v-slot:item.status="{ item }">
            <span v-if="item.aktif === '1'">Aktif</span>
            <span v-else>Tidak Aktif</span>
          </template>

          <template v-slot:item.aksi="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)" title="Edit"> {{ icons.mdiPencil }} </v-icon>
            <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
          </template>

          <template v-slot:no-data>
            <v-btn color="primary" small @click="initialize"> Reload</v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiPencil } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  setup() {
    return {
      icons: {
        mdiPencil,
      },
    }
  },
  data: () => ({
    // datatable

    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'No', value: 'no', sortable: false },
      { text: 'Nama', value: 'nama', sortable: false },
      { text: 'Maksimal Pinjam', value: 'maks_pinjam', sortable: false, align: 'right' },
      { text: 'Maksimal Angsur', value: 'lama_angsuran', sortable: false },
      { text: 'Bunga (Perbulan)', value: 'bunga', sortable: false },
      { text: 'Jenis Upah', value: 'jenis_upah', sortable: false },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Aksi', value: 'aksi', sortable: false },
    ],
    totalDesserts: 0,
    desserts: [],
    loading: true,
    options: { itemsPerPage: -1 },

    editedIndex: -1,
    editedItem: {
      id: '',
      nama: '',
      maks_pinjam: '',
      lama_angsuran: '',
      bunga: 2,
      jenis_upah: { text: 'HARIAN', value: 'HARIAN' },
      aktif: true,
    },
    defaultItem: {
      id: '',
      nama: '',
      maks_pinjam: '',
      lama_angsuran: '',
      bunga: '',
      jenis_upah: '',
      aktif: '',
    },
    jenis_upah: [
      { text: 'HARIAN', value: 'HARIAN' },
      { text: 'MINGGUAN', value: 'MINGGUAN' },
      { text: 'BULANAN', value: 'BULANAN' },
    ],
    search: '',
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Program Pinjaman' : 'Edit Program Pinjaman'
    },
    token() {
      return this.$store.getters.token
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },

  created() {
    // this.initialize()
  },

  methods: {
    initialize() {
      this.getDataFromApi()
    },
    getDataFromApi() {
      this.loading = true

      // const { page, itemsPerPage } = this.options

      const headers = {
        Authorization: this.token,
      }
      // const form = new FormData()
      // form.append('search', this.search)
      // form.append('page', page)
      // form.append('itemsPerPage', itemsPerPage)
      axios
        .get(`${apiRoot}/api/JenisPinjaman/getAll`, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
            this.totalDesserts = response.data.total
            this.loading = false
          } else {
            alert(response.data.message)
          }
        })
        .catch(error => {
          alert(error)
        })
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)

      // console.log(this.editedIndex)

      this.editedItem = Object.assign({}, item)

      // console.log(this.editedItem)
      this.editedItem.jenis_upah = { text: item.jenis_upah, value: item.jenis_upah }
      if (item.aktif === '1') {
        this.editedItem.aktif = true
      } else {
        this.editedItem.aktif = false
      }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        // Object.assign(this.desserts[this.editedIndex], this.editedItem)

        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('id', this.editedItem.id)
        form.append('nama', this.editedItem.nama)
        form.append('maks_pinjam', this.editedItem.maks_pinjam)
        form.append('lama_angsuran', this.editedItem.lama_angsuran)
        form.append('bunga', this.editedItem.bunga)
        form.append('aktif', this.editedItem.aktif)
        form.append('jenis_upah', this.editedItem.jenis_upah.value)
        axios
          .post(`${apiRoot}/api/JenisPinjaman/updateJenisPinjaman`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.search = ''
              this.options.page = 1
              this.getDataFromApi()
              this.close()
            } else {
              alert(response.data.message)
            }
          })
          .catch(error => {
            alert(error)
          })
      } else {
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('nama', this.editedItem.nama)
        form.append('maks_pinjam', this.editedItem.maks_pinjam)
        form.append('lama_angsuran', this.editedItem.lama_angsuran)
        form.append('bunga', this.editedItem.bunga)
        form.append('aktif', this.editedItem.aktif)
        form.append('jenis_upah', this.editedItem.jenis_upah.value)
        axios
          .post(`${apiRoot}/api/JenisPinjaman/add`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.search = ''
              this.options.page = 1
              this.getDataFromApi()
              this.close()
            } else {
              alert(response.data.message)
            }
          })
          .catch(error => {
            alert(error)
          })
      }
    },

    aktifCheck() {},
    searching() {
      this.options.page = 1
      this.getDataFromApi()
    },
  },
}
</script>
